import { render, staticRenderFns } from "./StandardSmallBorderedButton.vue?vue&type=template&id=7e878835&scoped=true&"
import script from "./StandardSmallBorderedButton.vue?vue&type=script&lang=js&"
export * from "./StandardSmallBorderedButton.vue?vue&type=script&lang=js&"
import style0 from "./StandardSmallBorderedButton.vue?vue&type=style&index=0&id=7e878835&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e878835",
  null
  
)

export default component.exports