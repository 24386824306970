<template>
  <div class="manager-event-messages" v-if="loaded">
    <h4 class="page-title">Create messages for your audience</h4>
    <p class="page-subtitle large">
      Create a message and choose who should see it. <br />
    </p>
    <StandardSolidButton
      v-if="eventData.eventMessages.length > 0 || createNewMessage == false"
      class="create-message-button"
      title="Create message"
      :desktop-fluidity="true"
      :fluid="false"
      @click="expandCreateNewMessage()"
    />
    <slide-up-down
      class="new-message-wrapper"
      id="new-message"
      :active="createNewMessage"
      :duration="300"
    >
      <div class="new-message">
        <div class="title">
          <DefaultTextInput
            v-model="eventMessageSubject"
            :maxlength="255"
            placeholder="Add message title (required)"
            :fat="true"
          />
        </div>
        <div class="new-message-body">
          <vue-editor
            :placeholder="'Add message body (optional)'"
            :class="{ error: eventMessageBody.length > 2500 }"
            v-model="eventMessageBody"
            :editor-toolbar="customToolbar"
          />
        </div>
        <div class="new-message-audience">
          <h5>Visible for</h5>
          <DefaultRadioButton
            groupname="audience"
            :value="eventMessageAudience"
            :options="{
              title: 'Public (everyone)',
              value: 'PUBLIC',
            }"
            @change="eventMessageAudience = $event"
          />
          <DefaultRadioButton
            groupname="audience"
            :value="eventMessageAudience"
            :options="{
              title: 'Confirmed players',
              value: 'ACCEPTED',
            }"
            @change="eventMessageAudience = $event"
          />
          <DefaultRadioButton
            groupname="audience"
            :value="eventMessageAudience"
            :options="{
              title: 'Waiting players',
              value: 'PENDING',
            }"
            @change="eventMessageAudience = $event"
          />
        </div>
        <div class="new-message-actions">
          <StandardBorderedButton
            title="Cancel"
            :fluid="false"
            @click="cancelNewMessage()"
          />
          <StandardSolidButton
            title="Post"
            :fluid="false"
            :disabled="!newMessageSaveable"
            @click="
              saveNewMessage(
                eventMessageSubject,
                eventMessageBody,
                eventMessageAudience
              )
            "
          />
        </div>
      </div>
      <div class="push-info-banner">
        <p>
          <b>
            Players using Tjing for iOS and Android will recieve push
            notifications</b
          >
        </p>
      </div>
    </slide-up-down>
    <div
      class="message"
      v-for="message in eventData.eventMessages"
      :key="message.id"
    >
      <slide-up-down
        class="message-wrapper"
        :active="
          eventMessageToEdit != null && eventMessageToEdit.id == message.id
        "
        :duration="300"
      >
        <div class="update-message">
          <div class="title">
            <DefaultTextInput
              v-model="message.subject"
              :maxlength="255"
              :invalid="message.subject.length == 0"
              placeholder="Add message title (required)"
              :fat="true"
            />
          </div>
          <div class="update-message-body">
            <vue-editor
              placeholder="Add message body (optional)"
              :class="{ error: message.message.length > 2500 }"
              v-model="message.message"
              :editor-toolbar="customToolbar"
            />
          </div>
          <div class="update-message-audience">
            <h5>Visible for</h5>
            <DefaultRadioButton
              :groupname="message.id + 'update-audience'"
              :value="message.audience"
              :options="{
                title: 'Public (everyone)',
                value: 'PUBLIC',
              }"
              @change="message.audience = $event"
            />
            <DefaultRadioButton
              :groupname="message.id + 'update-audience'"
              :value="message.audience"
              :options="{
                title: 'Confirmed players',
                value: 'ACCEPTED',
              }"
              @change="message.audience = $event"
            />
            <DefaultRadioButton
              :groupname="message.id + 'update-audience'"
              :value="message.audience"
              :options="{
                title: 'Waiting players',
                value: 'PENDING',
              }"
              @change="message.audience = $event"
            />
          </div>
          <div class="update-message-actions">
            <StandardBorderedButton
              title="Cancel"
              :fluid="false"
              @click="eventMessageToEdit = null"
            />
            <StandardSolidButton
              title="Update"
              :fluid="false"
              :disabled="!existingMessageSaveable(message)"
              @click="updateMessage(message)"
            />
          </div>
        </div>
      </slide-up-down>
      <slide-up-down
        class="message-wrapper"
        :active="
          eventMessageToEdit == null ||
          (eventMessageToEdit != null && eventMessageToEdit.id != message.id)
        "
        :duration="300"
      >
        <div class="message-subject">
          <p>
            <b>{{ message.subject }}</b>
          </p>
        </div>
        <div class="message-body" v-if="message.message">
          <vue-markdown :source="message.message" />
        </div>
        <div class="audience-label">
          <div class="public" v-if="message.audience == 'PUBLIC'">
            <p>Public (everyone)</p>
          </div>
          <div class="accepted" v-else-if="message.audience == 'ACCEPTED'">
            <p>Confirmed players</p>
          </div>
          <div class="waiting" v-else-if="message.audience == 'PENDING'">
            <p>Waiting players</p>
          </div>
        </div>
        <div class="message-footer">
          <div class="author-info">
            <p>
              Posted <b>{{ dateFormatter(message.createdAt) }}</b
              ><br class="responsive-linebreak" />
              by {{ message.author.firstName }} {{ message.author.lastName }}
            </p>
            <p
              class="desktop-spacer"
              v-if="message.createdAt != message.updatedAt"
            >
              •
            </p>
            <p v-if="message.createdAt != message.updatedAt" class="updater">
              Last updated <b>{{ dateFormatter(message.createdAt) }}</b
              ><br class="responsive-linebreak" />
              by {{ message.updatedBy.firstName }}
              {{ message.updatedBy.lastName }}
            </p>
          </div>
          <div class="actions">
            <StandardSmallBorderedButton
              :fluid="true"
              @click="deleteMessagePopUp(message)"
              title="Delete"
            />
            <StandardSmallSolidButton
              :fluid="true"
              @click="eventMessageToEdit = message"
              title="Edit"
            />
          </div>
        </div>
      </slide-up-down>
    </div>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="deletemessage"
      hide-close-button
    >
      <h3>Delete message</h3>
      <p>Are you sure you want to delete this message?</p>

      <div class="buttons">
        <StandardSolidButton
          title="Cancel"
          :fluid="false"
          @click="cancelDeleteMessage()"
        />
        <StandardBorderedButton
          class="forwards"
          title="Delete"
          :fluid="false"
          @click="deleteMessage(messageToDelete.id)"
        />
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import SlideUpDown from "vue-slide-up-down";
import moment from "moment";
import ClickOutside from "vue-click-outside";
import DefaultTextInput from "@/components/UIElements/DefaultTextInput";
import { VueEditor } from "vue2-editor";
import VueMarkdown from "vue-markdown";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";
import StandardSmallSolidButton from "@/components/UIElements/StandardSmallSolidButton";
import StandardSmallBorderedButton from "@/components/UIElements/StandardSmallBorderedButton";
import DefaultRadioButton from "@/components/UIElements/DefaultRadioButton";
export default {
  name: "EventMessages",
  components: {
    DefaultTextInput,
    VueEditor,
    VueMarkdown,
    StandardSolidButton,
    StandardBorderedButton,
    SlideUpDown,
    StandardSmallSolidButton,
    StandardSmallBorderedButton,
    DefaultRadioButton,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      eventData: {},
      loaded: false,
      createNewMessage: false,
      eventMessageToEdit: null,
      eventMessageToDelete: null,
      eventMessageBody: "",
      eventMessageBodyError: false,
      eventMessageSubject: "",
      eventMessageAudience: "PUBLIC",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },
  watch: {
    $route: "loadData",
  },
  computed: {
    deviceWidth() {
      return this.$store.getters.deviceWidth;
    },
    newMessageSaveable() {
      let isSaveable = true;
      if (
        this.eventMessageSubject.length < 3 ||
        this.eventMessageSubject.length > 255
      ) {
        isSaveable = false;
      }
      if (this.eventMessageBody.length > 2500) isSaveable = false;

      return isSaveable;
    },
  },
  methods: {
    expandCreateNewMessage() {
      this.createNewMessage = true;
      if (this.deviceWidth < 1200) {
        this.$nextTick(() =>
          this.$scrollTo("#new-message", {
            offset: -20,
          })
        );
      }
    },

    existingMessageSaveable(message) {
      let isSaveable = true;
      if (message.subject.length == 0 || message.subject.length > 255) {
        isSaveable = false;
      }
      if (message.message.length > 2500) isSaveable = false;

      return isSaveable;
    },
    deleteMessagePopUp(message) {
      this.messageToDelete = message;
      this.$refs.deletemessage.open();
    },
    cancelDeleteMessage() {
      this.$refs.deletemessage.close();
      this.messageToDelete = null;
    },
    async cancelEditMessage() {
      await this.loadData();
      this.eventMessageToEdit = null;
    },
    async deleteMessage(messageId) {
      try {
        const newMessage = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
              mutation{
                DeleteEventMessage(messageId:"${messageId}")
              }
            `,
          },
        });
        this.$refs.deletemessage.close();
        this.loadData();
      } catch (err) {
        console.log(err);
      }
    },
    dateFormatter(date) {
      return moment(date).format("D MMM, HH:mm");
    },
    cancelNewMessage() {
      this.createNewMessage = false;
      this.eventMessageAudience = "PUBLIC";
      this.eventMessageSubject = "";
      this.eventMessageBody = "";
    },
    async updateMessage(message) {
      try {
        const newMessage = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation UpdateEventMessage($UpdateEventMessage: UpdateEventMessageInput!){
              UpdateEventMessage(messageId:"${message.id}",input:$UpdateEventMessage){
                id
              }
            }
          `,
            variables: {
              UpdateEventMessage: {
                subject: message.subject,
                message: message.message,
                audience: message.audience,
              },
            },
          },
        });
        this.cancelEditMessage();
      } catch (err) {
        console.log(err);
      }
    },
    async saveNewMessage(subject, message, audience) {
      try {
        const newMessage = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation CreateEventMessage($CreateEventMessage: CreateEventMessageInput!){
            CreateEventMessage(eventId:"${this.$route.params.eventId}",input:$CreateEventMessage){
              id
            }
          }
          `,
            variables: {
              CreateEventMessage: {
                messageType: "EVENT_POST",
                subject,
                message,
                audience,
              },
            },
          },
        });
        this.loadData();
        this.createNewMessage = false;
        this.eventMessageSubject = "";
        this.eventMessageAudience = "PUBLIC";
        this.eventMessageBody = "";
      } catch (err) {
        console.log(err);
      }
    },
    async loadData() {
      try {
        const eventMessages = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
              {
                event(eventId:"${this.$router.currentRoute.params.eventId}"){
                  eventMessages{
                    id
                    eventId
                    subject
                    message
                    audience
                    createdAt
                    updatedAt
                    updatedBy{
                      id
                      firstName
                      lastName
                    }
                    author{
                      id
                      firstName
                      lastName
                      email
                    }
                  }
                }
              }
            `,
          },
        });
        this.eventData = eventMessages.data.data.event;
        this.eventData.eventMessages.length == 0
          ? (this.createNewMessage = true)
          : (this.createNewMessage = false);
        this.loaded = true;
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss">
.quillWrapper {
  position: relative;
  margin-bottom: 64px;

  &.error {
    .ql-toolbar {
      &.ql-snow {
        border: 2px solid $dusk !important;
        border-top: 1px solid $sleet !important;
      }
    }
    .ql-container {
      &.ql-snow {
        border: 2px solid $dusk !important;
        border-bottom: 1px solid $fog !important;
      }
    }
  }

  .ql-editor {
    min-height: 135px;

    &.ql-blank {
      &::before {
        @include Gilroy-Medium;
        color: $blizzard;
      }
    }
  }

  .ql-toolbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(100%);
    &.ql-snow {
      border: 1px solid $blizzard !important;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border-top: 1px solid $sleet !important;
      background: $snow !important;

      button {
        :hover {
          .ql-stroke,
          .ql-fill {
            stroke: $twilight !important;
          }
        }
      }

      .ql-active {
        .ql-stroke,
        .ql-fill {
          stroke: $twilight !important;
        }
      }
    }
  }
  .ql-container {
    margin-top: 24px;
    &.ql-snow {
      border: 1px solid $blizzard !important;
      border-bottom: 1px solid $fog !important;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }
}

.manager-event-messages {
  .message {
    .message-body,
    .quillWrapper {
      @import "@/styles/Typography.scss";
    }
  }
}

@media (min-width: 1024px) {
  .quillWrapper {
    margin-bottom: 46px;

    .ql-container {
      margin-top: 0;
    }
  }
}
</style>

<style scoped lang="scss" >
@import "@/styles/Typography.scss";

.manager-event-messages {
  padding: 0 12px;
}

.page-title {
  padding: 0 6px;
}

.page-subtitle {
  margin-top: 12px;
  padding: 0 6px;
  line-height: 1.5rem;
}

.create-message-button {
  margin: 20px 0 32px;
}

.new-message-wrapper {
  margin-top: 18px;
  margin-bottom: 20px;
}

.new-message {
  background: white;
  padding: 16px 12px 32px 12px;
  border-radius: 10px 10px 0 0;
  border: 1px solid $sleet;
  border-bottom: none;
}

.new-message-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  button {
    max-width: 47%;
  }
}

.new-message-audience {
  .default-radio-buttons {
    margin-top: 16px;
  }
}

.push-info-banner {
  background: #fff4d9;
  border: 1px solid $sleet;
  border-top: none;
  border-radius: 0 0 10px 10px;
  padding: 12px;
  p {
    color: $sunrise;
    text-align: center;
  }
}
.update-message {
  background: white;
  padding: 16px;
  border-radius: 10px;
  width: 100%;
}

.update-message-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  button {
    max-width: 47%;
    p {
      @include Gilroy-Bold;
    }
  }
}

.update-message-audience {
  .default-radio-buttons {
    margin-top: 16px;
  }
}
.message {
  border-radius: 10px;
  border: 1px solid $sleet;
  background: white;
  margin-bottom: 20px;
}

.message-subject {
  padding: 16px 16px 0 16px;
}
.message-body {
  padding: 12px 16px 0 16px;
}

.audience-label {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  padding-left: 16px;
  p {
    font-size: 0.625rem;
    text-transform: uppercase;
    @include Gilroy-Bold;
    letter-spacing: 0.6px;
  }

  > div {
    border-radius: 12px;
    display: flex;
    padding: 6px 12px 6px 12px;

    &.public {
      background: #e5faf5;
      color: $grass;
    }
    &.accepted {
      background: #e5f8f8;
      color: $ocean;
    }
    &.waiting {
      background: #fff0ec;
      color: $sunset;
    }
  }
}

.message-footer {
  border-top: 1px solid $sleet;
  margin-top: 20px;
  padding: 12px 16px 16px 16px;
  background: $snow;
  border-radius: 0 0px 10px 10px;
  display: flex;
  p {
    color: $strom;
  }

  .author-info {
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    p,
    b {
      font-size: 0.75rem;
    }
    .desktop-spacer {
      display: none;
    }
    .updater {
      margin-top: 10px;
    }
  }
  .actions {
    flex: 5;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    button {
      margin-left: 12px;
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1024px) {
  .push-info-banner {
    p {
      text-align: center;
      padding: 6px 0;
    }
  }
  .new-message-wrapper {
    width: 100%;
    margin-top: 32px;
  }
  .new-message {
    padding: 30px 30px 32px 30px;
    grid-auto-columns: 1fr;
    display: grid;
    row-gap: 0;
    margin: 0;
    .title {
      grid-column: 1 / span 7;
      grid-row: 1;
      .default-inputfield {
        width: 100%;
      }
    }
    .new-message-body {
      margin-top: 24px;
      grid-column: 1 / span 7;
      grid-row: 2 / span 4;
    }
    .new-message-audience {
      grid-column: 9 / span 2;
      grid-row: 1 / span 2;
    }
    .new-message-actions {
      grid-column: 9 / span 2;
      grid-row: 5 / span 1;
    }
  }

  .update-message {
    padding: 30px 30px 32px 30px;
    grid-auto-columns: 1fr;
    display: grid;
    row-gap: 0;

    .title {
      grid-column: 1 / span 7;
      grid-row: 1;
      .default-inputfield {
        width: 100%;
      }
    }
    .update-message-body {
      margin-top: 24px;
      grid-column: 1 / span 7;
      grid-row: 2 / span 4;
    }
    .update-message-audience {
      grid-column: 9 / span 2;
      grid-row: 1 / span 2;
    }
    .update-message-actions {
      grid-column: 9 / span 2;
      grid-row: 5 / span 1;
    }
  }

  .manager-event-messages {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }

  .page-title {
    width: 100%;
  }
  .page-subtitle {
    margin-bottom: 22px;
  }
  .create-message-button {
    margin: 0 0 22px 0;
  }

  .message {
    margin-top: 20px;
    width: 100%;
  }

  .message-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .message-subject {
    order: 0;
    flex: 75%;
    padding: 24px 0 0 30px;
  }
  .message-body {
    order: 3;
    width: 75%;
    padding: 20px 30px 0 30px;
  }
  .audience-label {
    order: 1;
    flex: 20%;
    display: flex;
    justify-content: flex-end;
    padding: 24px 30px 0 0;
    margin: 0;
  }
  .message-footer {
    order: 4;
    flex: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px 22px 30px;
    .author-info {
      flex: 60%;
      flex-direction: row;
      justify-content: flex-start;

      .desktop-spacer {
        display: block;
        margin: 0 8px;
      }

      .responsive-linebreak {
        display: none;
      }
      .updater {
        margin: 0;
      }
    }
  }
}
</style>
